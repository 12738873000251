<template>
    <main>
        <Navbar />
        <div class="main">
            <div class="container">
                <div class="row ml-0 mt-3 mb-3 mr-0">
                    <div class="col pl-0" :class="{'d-contents': platform == 'mobile'}">
                        <select class="form-control" :class="{'mb-3': platform == 'mobile'}" @change="searchUser">
                            <option value="" >Todos</option>
                            <option v-for="(item, index) in logsOrganizes(logs)" :value="item.usuario" :key="index">{{item.usuario}}</option>
                        </select>
                    </div><!-- /col -->
                    <div class="col pl-0" :class="{'d-contents': platform == 'mobile'}">
                        <input class="form-control" :class="{'mb-3': platform == 'mobile'}" type="text" v-model="search" placeholder="Pesquisar usuário...">
                    </div><!-- /col -->
                    <div class="col pl-0 pr-0" :class="{'d-contents': platform == 'mobile'}">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <div class="input-group-text" style="width:63.84px">Data</div>
                            </div>
                            <input class="form-control" type="date" @change="searchLogs">
                        </div>
                    </div><!-- /col -->
                </div><!-- /row -->
                <div class="mt-3 mb-3">
                    <div v-show="loading == true" class="alert alert-warning" role="alert">
                        <div class="alert-heading text-center mb-0">
                            <div class="spinner-border mr-2 align-middle" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <span>Carregando, aguarde...</span>
                        </div>
                    </div><!-- /alert-warning -->
                    <div v-show="!loading && error.length > 0" class="alert alert-danger" role="alert">
                        <h4 class="alert-heading">Ops, ocorreu um problema!</h4>
                        <hr />
                        <p class="mb-0">{{error}}</p>
                    </div><!-- /alert-danger -->
                    <div v-show="!loading && nothing_found == true" class="alert alert-info" role="alert">
                        <h4 class="alert-heading">Ops, ocorreu um problema!</h4>
                        <hr />
                        <p class="mb-0">Nenhum log encontrado para a data informada!</p>
                    </div><!-- /alert-danger -->
                    <div class="card mb-3" v-for="(item, index) in filteredLogs" :key="index">
                        <div class="card-header">
                            <div class="row">
                                <div class="col-12 text-truncate">{{item.usuario}}</div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row border-bottom pb-2">
                                <div class="col text-truncate">Função</div>
                                <div class="col text-truncate text-danger">{{item.funcao | dictionary}}</div>
                            </div><!-- /row -->
                            <div class="row border-bottom pt-2 pb-2">
                                <div class="col text-truncate">IP do usuário</div>
                                <div class="col text-truncate">{{item.ip}}</div>
                            </div><!-- /row -->
                            <div class="row pt-2">
                                <div class="col text-truncate">Horário da operação</div>
                                <div class="col text-truncate">{{item.horario | formatDate}}</div>
                            </div><!-- /row -->
                        </div><!-- /card-body -->
                    </div><!-- /card -->
                </div><!-- /mt-3 mb-3 -->
            </div><!-- /container -->
        </div><!-- /main -->
      <Loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true"></Loading>
    </main>
</template>

<script>
import {api} from '../api'
import Navbar from './Navbar'
import moment from 'moment-timezone'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
    data() {
        return {
            error: '',
            logs: [],
            search: '',
            loading: false,
            isLoading: false,
            nothing_found: false,
            platform: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? 'mobile' : 'desktop'
        }
    },
    components: {
        Navbar,
        Loading
    },
    methods: {
        searchLogs(event) {

            const self = this;

            self.logs = [];
            self.error = '';
            self.loading = true;
            self.nothing_found = false;

            api.get(`dashboard/logs/${event.target.value}`).then((response) => {
                switch(response.data.result) {
                    case 'success':
                        self.logs = response.data.logs.sort((a, b) => {

                            if (self.removeAccents(a.usuario) < self.removeAccents(b.usuario)) return -1;
                            if (self.removeAccents(a.usuario) > self.removeAccents(b.usuario)) return 1;

                            if (a.horario < b.horario) return 1;
                            if (a.horario > b.horario) return -1;

                            return 0;
                        });
                    break;
                    case 'nothing_found':
                        self.nothing_found = true;
                    break;
                    default:
                        self.error = 'Ocorreu um erro, tente novamente!';
                    break;
                }
            }).catch((error) => {
                try {
                    self.error = self.network_erros[error.status]['message'];
                } catch(e) {
                    self.error = self.network_erros[408]['message'];
                }
            }).finally(() => {
                self.loading = false;
            });
        },
        searchUser(event) {
            this.search = event.target.value;
        },
        logsOrganizes(arr) {
            return arr.reduce((unique, o) => {
                if(!unique.some(obj => obj.usuario === o.usuario)) {
                    unique.push(o);
                }
                return unique;
            },[]).sort((a, b) => {
                return a.usuario.localeCompare(b.usuario)
            });
        },
        removeAccents(str) {
            
            let string = str;
            let map = {a : /[\xE0-\xE6]/g, A : /[\xC0-\xC6]/g, e : /[\xE8-\xEB]/g, E : /[\xC8-\xCB]/g, i : /[\xEC-\xEF]/g, I : /[\xCC-\xCF]/g, o : /[\xF2-\xF6]/g, O : /[\xD2-\xD6]/g, u : /[\xF9-\xFC]/g, U : /[\xD9-\xDC]/g, c : /\xE7/g, C : /\xC7/g, n : /\xF1/g, N : /\xD1/g};

            for (let key in map) {
                 let rex = map[key];
                 string = string.replace(rex, key);
            }

            return string;
        },
        formatDate(date) {
            return moment(date).tz('America/Recife').format('DD/MM/YYYY HH:mm');
        }
    },
    filters: {
        dictionary(value) {

            let str = '';

            if (value == '/dashboard/user') {
                str = 'Cadastrou um novo usuário!';
            } else if (value == '/dashboard/edit-user') {
                str = 'Editou as informações de um usuário!';
            } else if (value == '/dashboard/releases') {
                str = 'Fez um novo lançamento de caixa!';
            } else if (value == '/dashboard/configs') {
                str = 'Alterou as configurações do sistema!';
            } else if (value == '/dashboard/edit-user/commissions') {
                str = 'Alterou as comissões de um usuário!'
            } else if (value == '/dashboard/change-password') {
                str = 'Alterou a senha de um usuário!';
            } else if (value == '/dashboard/edit-odds') {
                str = 'Alterou as odds de uma aposta!';
            } else if (value == '/dashboard/odds') {
                str = 'Alterou as odds gerais do sistema!';
            } else if (value == '/dashboard/edit-bank-details') {
                str = 'Alterou os dados bancários do cliente!'
            } else if (value == '/dashboard/edit-event') {
                str = 'Alterou os dados de um evento!';
            } else if (value == '/dashboard/close-box') {
                str = 'Fechou o caixa de todos os vendedores!';
            } else if (value == '/dashboard/rules-leagues') {
                str = 'Alterou as regras de odds de uma liga!';
            } else if (value == '/dashboard/rules-salesman') {
                str = 'Alterou as regras de odds de um vendedor!';
            } else if (value == '/dashboard/rules-managers') {
                str = 'Alterou as regras de odds de um gerente!';
            } else if (value == '/dashboard/event/edit/odds') {
                str = 'Alterou as odds manualmente do evento!';
            } else if (value == '/dashboard/challenge/edit') {
                str = 'Alterou as informações de um desafio!';
            } else if (value == '/dashboard/challenge/edit/odds') {
                str = 'Alterou as odds de um desafio!';
            } else if (value == '/dashboard/commission-standard') {
                str = 'Alterou o padrão de comissão!';
            } else if (value.includes('/dashboard/change-status')) {
                str = 'Alterou o status de uma aposta!';
            } else if (value.includes('/dashboard/change-credit')) {
                str = 'Alterou o crédito de um usuário!';
            } else if (value.includes('/dashboard/cancel-bet')) {
                str = 'Cancelou uma aposta de um vendedor!';
            } else if (value.includes('/dashboard/lock-unlock-user/')) {
                str = 'Bloqueou ou desbloqueou um usuário!'
            } else if (value.includes('/dashboard/change-manager')) {
                str = 'Alterou ou adicionou um gerente em um vendedor!';
            } else if (value.includes('/dashboard/delete-user')) {
                str = 'Excluiu um usuário do sistema!';
            } else if (value.includes('/dashboard/confirm-pay')) {
                str = 'Confirmou o pagamento de um cliente!'
            } else if (value.includes('/dashboard/lock-unlock-event')) {
                str = 'Bloqueou ou desbloqueou um evento!';
            } else if (value.includes('/dashboard/lock-unlock-league')) {
                str = 'Bloqueou ou desbloqueou uma liga!';
            } else if (value.includes('/dashboard/accumulated/lock-unlock-event')) {
                str = 'Bloqueou ou desbloqueou um acumuladão!';
            } else if (value.includes('/dashboard/accumulated')) {
                str = 'Excluiu ou criou um acumuladão!';
            } else if (value.includes('/dashboard/cancel-bet')) {
                str = 'Cancelou uma aposta!';
            } else if (value.includes('/dashboard/recover-bet')) {
                str = 'Recuperou uma aposta excluída!';
            } else if (value.includes('/dashboard/give-money-back-bet')) {
                str = 'Devolveu o dinheiro de uma aposta!';
            } else if (value.includes('/dashboard/delete-bet')) {
                str = 'Excluiu uma aposta!';
            } else if (value.includes('/dashboard/close-box')) {
                str = 'Fechou o caixa de um vendedor!';
            } else if (value.includes('/dashboard/releases/')) {
                str = 'Excluiu um lançamento!';
            } else if (value.includes('/dashboard/challenge')) {
                str = 'Excluiu ou criou um desafio!';
            } else if (value.includes('/dashboard/challenge/')) {
                str = 'Finalizou um desafio!';
            } else if (value.includes('/dashboard/cancel-event')) {
                str = 'Cancelou um evento!'
            } else {
                str = value;
            }

            return str;
        },
        formatDate(date) {
            return moment(date).tz('America/Recife').format('DD/MM/YYYY HH:mm');
        }
    },
    computed: {
        filteredLogs() {
            return this.logs.filter((item) => {
                return item.usuario.toLowerCase().trim().includes(this.search.toLowerCase().trim())
            });
        }
    },
    created() {
        window.addEventListener('resize', () => {
            this.platform = this.isMobile();
        });
    }
}
</script>

<style scoped>
.main {
    margin-top: 80px;
}
.d-contents {
    display: contents!important;
}
</style>